<template>
    <div class="bottom-navigation-wrapper" :class="{ 'bottom-navigation-hidden': hide }">
        <div class="bottom-navigation">
            <div class="progress-bar" @click="openBetslipMenu">
                <div class="progress-bar-text">
                    <span class="progress-text truncate" data-test-id="bottomProgressInfo">
                        <template v-if="odds > 1">
                            <span class="bold">{{ $t('ui.openBetslip.odds') }}: </span>
                            <span class="total-odds truncate">{{ $numberFormat(odds) }}</span>
                            <span v-if="hasBonusesData" class="bold divider-symbol">&#183;</span>
                        </template>
                        <template>
                            <span v-if="winBonus">
                                <span class="bold">{{ bonusWinType }}:</span>
                                <span> {{ bonusInfoMessage }}</span>
                            </span>
                            <span v-else-if="hasBonusesData">
                                <renderer :input="legInfoMessage" />
                            </span>
                        </template>
                    </span>
                </div>
                <div class="progress-bar-fill" :style="{ width: bonusPercentage + '%' }"></div>
            </div>
            <div class="navigation-menu">
                <div
                    v-for="menuItem of menuItems"
                    :key="menuItem.name"
                    :data-test-id="`bottomMenu_${menuItem.name}`"
                    class="navigation-item"
                    :class="{ transparent: menuItem.name === 'betslip' }"
                >
                    <NavigationItem :menuItem="menuItem" :ref="menuItem.name" />
                </div>
            </div>
            <BottomMenu v-if="showBottomMenu" />
        </div>
    </div>
</template>

<script>
import { deviceType, helper } from '@agi.packages/core';
import { betslip } from '@agi.packages/sport';
import { auth, getter as platformGetter } from '@agi.packages/platform';
import { mapGetters, mapState } from 'vuex';
import NavigationItem from '@/components/Fragments/NavigationItem';
import BottomMenu from '@/components/Fragments/BottomMenu';

export default {
    name: 'BottomNavigation',
    components: { NavigationItem, BottomMenu },
    props: {
        hide: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    data() {
        return {
            isPresto: deviceType.isPresto(),
            menuList: [
                {
                    name: 'menu',
                    icon: 'icon-hamburger',
                    url: '',
                    action: 'openSidebar',
                    activeKey: 'sidebarOpen',
                },
                {
                    name: 'sports',
                    icon: `icon-football`,
                    url: '/upcoming',
                },
                {
                    name: 'betslip',
                    icon: 'icon-betslip',
                    url: '',
                    action: 'openBetslip',
                    activeKey: 'betslipOpen',
                },
                {
                    name: 'join',
                    icon: 'icon-join-now',
                    url: '/join-now',
                    requireAuth: '0',
                },
                {
                    name: 'myBets',
                    icon: 'icon-mybets-menu',
                    url: '/bets',
                    requireAuth: '1',
                },
                {
                    name: 'account',
                    icon: 'icon-top-menu',
                    url: '',
                    action: 'openBottomMenu',
                    activeKey: 'showBottomMenu',
                },
            ],
        };
    },
    computed: {
        ...mapState({
            showBottomMenu: (state) => state.ui.bottomMenuOpen,
            rounds: (state) => state.sport.virtualSports.rounds,
        }),
        ...mapGetters({
            odds: betslip.getter.GET_TOTAL_ODDS,
            selectedBetslip: betslip.getter.GET_SELECTED_BETSLIP,
            legInfoData: betslip.getter.GET_LEG_INFO_DATA,
            hasBonusesData: betslip.getter.HAS_BONUSES_DATA,
            totalBonus: betslip.getter.GET_TOTAL_BONUS,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        menuItems() {
            return this.menuList
                .filter((item) => helper.processMenuAuth(item.requireAuth, this.isAuthenticated))
                .map((item) => {
                    if (item.name === 'account' && this.isAuthenticated) {
                        return {
                            name: item.name,
                            icon: item.icon,
                            url: '/account',
                        };
                    }
                    return item;
                });
        },
        betCount() {
            const { selectedBetsIds } = this.selectedBetslip;
            return selectedBetsIds.length;
        },
        legInfoMessage() {
            const { currentSelections, nextTierPercentage, selectionsToNextTier } = this.legInfoData;
            if (!currentSelections) {
                return this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'noLegsBoost' : 'noLegs'}`, {
                    legs: selectionsToNextTier,
                    totalBonus: this.totalBonus,
                });
            }

            return `${this.$t('ui.openBetslip.legs', {
                legs: selectionsToNextTier,
                bonuses: nextTierPercentage,
                winBonus: this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'winBoost' : 'winBonus'}`),
                moreSelectionSuffix: selectionsToNextTier === 1 ? '' : 's ',
            })}`;
        },
        bonusWinType() {
            return this.$t(`ui.openBetslip.${this.countryCodeIs.KE ? 'winBoost' : 'winBonus'}`);
        },
        bonusInfoMessage() {
            if (this.winBonus === this.totalBonus) {
                return `${this.winBonus}%`;
            }
            return this.$t('ui.openBetslip.bonusPercentage', {
                currentBonus: this.winBonus,
                totalBonus: this.totalBonus,
            });
        },
        winBonus() {
            return this.legInfoData.currentWinBonus;
        },
        bonusPercentage() {
            return (this.winBonus / this.totalBonus) * 100;
        },
    },
    methods: {
        $numberFormat: helper.numberFormat,
        openBetslipMenu() {
            const [betslipItem] = this.$refs['betslip'];
            betslipItem.toggleBetslip();
        },
    },
};
</script>

<style scoped lang="scss">
$full-height: (
    'default': 90px,
    'oldschool': 80px,
);
$menu-height: (
    'default': 65px,
    'oldschool': 55px,
);
$progress-text-height: (
    'default': map-get($full-height, 'default') - map-get($menu-height, 'default'),
    'oldschool': map-get($full-height, 'oldschool') - map-get($menu-height, 'oldschool'),
);

.bottom-navigation-wrapper {
    position: relative;
    height: map-get($full-height, 'default');
    transition: height 0s 0.35s linear;

    @include oldschool {
        height: map-get($full-height, 'oldschool');
    }

    &.bottom-navigation-hidden {
        transition: height 0.3s 0s linear;
        height: 0;
    }
}

.bottom-navigation {
    position: absolute;
    top: calc(100% - #{map-get($full-height, 'default')});
    width: 100%;
    height: map-get($full-height, 'default');
    transition: top 0.3s 0.35s ease-in-out, opacity 0s 0s linear, height 0s 0s linear;
    flex-shrink: 0;

    .bottom-navigation-hidden & {
        transition-delay: 0s, 0.35s, 0.6s;
        top: 100%;
    }

    @include oldschool {
        height: map-get($full-height, 'oldschool');

        .bottom-navigation-hidden & {
            top: 100%;
        }
    }
    .progress-bar {
        position: relative;
        height: inherit;
        background-color: $primary-color;

        &-text {
            @extend %small-details-font-400;
            position: absolute;
            padding: 4px 12px;
            z-index: 1;
            width: 100%;
            top: 0;
            left: 0;
            height: map-get($progress-text-height, 'default');
            display: flex;
            align-items: center;

            @include oldschool {
                height: map-get($progress-text-height, 'oldschool');
            }
        }

        &-fill {
            position: absolute;
            height: 100%;
            top: 0;
            left: 0;
            background-color: $navigation-progress-bar-fill-color;
        }
    }

    .navigation-menu {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: absolute;
        width: 100%;
        bottom: 0;
        height: map-get($menu-height, 'default');

        @include oldschool {
            height: map-get($menu-height, 'oldschool');
        }
    }

    .navigation-item {
        @extend %small-details-font-400;
        color: $navigation-menu-color;
        width: 100%;
        height: 100%;
        text-align: center;
        padding: 10px 0 16px;
        background-color: $betpawa-black;

        @include oldschool {
            padding: 0;
        }
    }

    .transparent {
        background-color: transparent;
        padding: 0;
    }

    .divider-symbol {
        margin: 0 4px;
    }

    .total-odds {
        display: inline-block;
        max-width: calc(50% - 42px);
        margin-bottom: -3px;
    }
}

.progress-text {
    flex-shrink: 1;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    width: 100%;
}
</style>
