<template>
    <div :class="['betslip-main', { 'placed-betslip': betslip.status.Placed }]" v-if="betting">
        <Tabs v-if="isVirtualEnabled" :tabs="tabs" :active="currentTab" @select="setActiveTab">
            <template #tab-suffix="{ tab }">
                <span v-if="tab.count" :class="{ 'tab-counter': true, active: tab.key === currentTab.key }">
                    {{ tab.count }}
                </span>
            </template>
        </Tabs>
        <template v-if="betslip.bets.length">
            <small v-if="!betslip.status.Placed && isVirtualBetslip && betslip.season.id">{{ season }}</small>
            <BetBonus v-if="showLegInfo" :legBonusInfo="legBonusInfo" />
        </template>
        <BookingCode
            v-show="!betslip.status.Placed"
            :input-visible="!isVirtualBetslip && !betslip.bets.length"
            :toolbar-visible="!controlsDisabled"
        >
            <template slot="toolbar">
                <div class="table betslip-toolbar">
                    <div class="row-cell align-middle">
                        <a
                            v-if="!isVirtualBetslip"
                            :disabled="bookingInProgress || allPricesOffline"
                            class="underline booking-code-link"
                            @click="openBetSharingModal()"
                        >
                            <SvgIcon icon-id="icon-share" verticalAlign="top" />
                            <span>{{ $t('ui.bookingCode.bookingCode') }}</span>
                        </a>
                    </div>
                    <div class="row-cell align-middle right">
                        <a @click="resetSlip({ track: true })" class="underline">{{ $t('ui.betslip.clearSlip') }}</a>
                    </div>
                </div>
            </template>
        </BookingCode>
        <template v-if="betslip.bets.length">
            <BetList :bets="betslip.bets" :offlinePrices="offlinePrices" v-show="!betslip.status.Placed" @remove="removeBet($event)" />
            <PlacedBetslip v-if="betslip.status.Placed" :selection="selection.availableBets" @resetBetslip="resetSlip()" />
            <div :class="['betslip-bottom', isVirtualBetslip && 'virtual']" v-if="!betslip.status.Placed">
                <div class="accept-change" v-if="!isVirtualBetslip && !betslip.status.Placed">
                    <Checkbox id="acceptAnyPrice" ref="priceAccept" :checked="acceptAnyPrice" @onCheck="onAcceptChange">
                        <span>
                            {{ $t('ui.betslip.acceptOddsChange') }}
                            <router-link :to="{ path: '/accept-odds-changes' }" class="underline change-link">
                                {{ $t('ui.common.learnMore') }}
                            </router-link>
                        </span>
                    </Checkbox>
                </div>
                <CampaignMessage v-if="showCampaignMessage" :campaign="campaignEligibility" />
                <form v-show="!betslip.status.Placed" v-on:submit.prevent class="betslip-form">
                    <div>
                        <InputField
                            name="stake-input"
                            type="number"
                            formName="betslip-form"
                            labelClass="bold"
                            :value="stake"
                            @value="setStake({ stake: $event, type })"
                            :attrs="{
                                step: stakeStep,
                                min: 0,
                                placeholder: placeholderInput,
                                disabled: betInProgress || allPricesOffline,
                                inputmode: 'decimal',
                            }"
                            :handlers="{
                                keydown: handleKeydownBetAmount,
                                'submit.prevent': true,
                            }"
                            :helpText="$t('ui.betslip.minStake', { minStake: betting.minStake })"
                        >
                            <template slot="label">{{ $t('ui.betslip.stake') }}</template>
                        </InputField>
                        <BetDetails
                            :stake="stake"
                            :isPriceShown="!!betslip.bets.length && !allPricesOffline"
                            :currentBonus="currentBonus"
                            :tax="tax"
                            :taxableWinnings="taxableWinnings"
                            :taxTier="taxTier"
                            :totalWinnings="totalWinnings"
                            :totalWinBonus="totalWinBonus"
                            :totalAmount="totalAmount"
                            :pawaBonus="pawaBonus"
                            :stakeAfterTax="stakeAfterTax"
                            :multiplier="multiplier"
                            :multiplierTotalOdds="multiplierTotalOdds"
                            :isWht="isWht"
                            :isTaxEnabled="isTaxEnabled"
                            :isWinTaxEnabled="isWinTaxEnabled"
                            :taxAmount="taxAmount"
                        />
                    </div>
                    <div v-if="isErrorVisible" class="notify error" data-test-class="betSlipStatus">
                        <template v-if="isNAError && !shouldDeposit">
                            <renderer
                                @clickEvent="clickEvent"
                                :input="$t(`ui.betslip.error.${allPricesOffline ? 'allPricesOffline' : 'somePricesOffline'}`)"
                            />
                        </template>
                        <template v-if="!noLongerAvailableCode.includes(error.code)">
                            <template v-if="offlinePrices.length && error.message && !shouldDeposit">
                                <br />
                                <br />
                            </template>
                            <renderer v-if="error.message" :input="error.message" @clickEvent="clickEvent" />
                        </template>
                    </div>
                    <div class="notify warning" v-show="betInProgress">
                        <span v-if="isPresto">{{ $t('ui.betslip.placingBetPresto') }}</span>
                        <Spinner v-else :listen="spinnerTrigger" type="bp-spinner">
                            {{ spinnerDescription }}
                        </Spinner>
                    </div>
                    <ProgressiveJpBetMessage
                        v-if="sportProgressiveJackpot && !isVirtualBetslip"
                        :frequency="sportProgressiveJackpot.frequency"
                    />
                    <div class="table action-buttons">
                        <div class="row-cell place-bet">
                            <input
                                type="submit"
                                v-show="!(isPresto && betInProgress)"
                                :value="dynamicCTATranslations"
                                @click="placeBet()"
                                :disabled="isCTADisabled"
                                class="place-bet button button-primary button-full"
                            />
                            <input
                                type="submit"
                                v-if="isPresto && betInProgress"
                                :value="$t('ui.betslip.reload')"
                                @click="refreshBetslipPresto()"
                                class="place-bet button button-primary button-full"
                            />
                        </div>
                    </div>
                    <div v-if="shouldJoin" class="join-now-text">
                        {{ $t('ui.common.user.joinNowText') }}
                        <router-link :to="{ name: 'JoinNow' }" class="bold underline" @click.native="trackLinkClick">
                            {{ $t('ui.common.user.joinNow') }}
                        </router-link>
                    </div>
                    <div class="betslip-tools">
                        <div v-if="cashoutAvailable && false" :class="['cashout-availability', { available: isCashoutable }]">
                            <SvgIcon iconId="icon-cashoutable" :title="$t('ui.cashout.cashout')" class="icon-cashoutable" />
                            <span class="cashout-availability-description">
                                {{ $t(`ui.cashout.${isCashoutable ? 'cashoutAvailable' : 'cashoutUnavailable'}`) }}
                            </span>
                        </div>
                    </div>
                </form>
            </div>
            <div data-test-id="adminEventId" v-if="isAdmin && betslip.bets.length" class="admin">
                <span>{{ betslip.bets.map((item) => item.eventId).join() }}</span>
            </div>
        </template>
        <template v-else>
            <div class="empty-betslip">
                <div class="empty-betslip-title">{{ $t('ui.betslip.betslipIsEmpty') }}</div>
                <SvgIcon iconId="empty-betslip" />
                <router-link :to="`/learn-how-to-bet${isVirtualBetslip ? '-virtual' : ''}`" class="button button-accent button-full">
                    {{ $t('ui.betslip.learnHowToPlaceBet') }}
                </router-link>
            </div>
        </template>
    </div>
</template>

<script>
import { mapState, mapGetters, mapMutations, mapActions } from 'vuex';
import { helper, android, deviceType, getter as coreGetter } from '@agi.packages/core';
import { InputField, Spinner, Tabs } from '@agi.packages/core/components';
import { betslip, betslipType, GROSS_TAX_TYPES, whtTax, stakeFormat, betslipRedirects } from '@agi.packages/sport';
import {
    auth,
    postAuthenticationRouteQueries,
    getter as platformGetter,
    action,
    mutation as platformMutation,
} from '@agi.packages/platform';
import { mutation as storeMutation, action as storeAction } from '@/store/store';

import Checkbox from '@/components/Checkbox.vue';

import PlacedBetslip from './Betslip/PlacedBetslip';
import BetDetails from './Betslip/BetDetails';
import BetBonus from './Betslip/BetBonus';
import BetList from './Betslip/BetList';
import BookingCode from '../BookingCode';
import CampaignMessage from './Betslip/CampaignMessage';
import ProgressiveJpBetMessage from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpBetMessage.vue';
import { prestoScrollTop } from '@/modules/core/utils/prestoUtils';

const ELIGIBLE_ODDS_CHANGED_ERROR_CODE = [8036, '8036', 'FOB_BONUS_PRICES_CHANGED'];
const SERVER_BUSY_ERROR_CODE = [8016, '8016', 'FOB_QUEUE_IS_FULL'];
const NO_LONGER_AVAILABLE_CODE = [8060, '8060', 'FOB_OFFLINE_PRICES'];
const NOT_ENOUGH_MONEY_CODE = [8026, '8026', 'FOB_NOT_ENOUGH_MONEY'];
const FORBIDDEN_CHARACTERS = ['e', '+', '-'];
const ALLOWED_DECIMAL_SEPARATORS = ['.', ','];

export default {
    name: 'Betslip',
    components: {
        ProgressiveJpBetMessage,
        Spinner,
        PlacedBetslip,
        InputField,
        BetDetails,
        BetBonus,
        BetList,
        Tabs,
        BookingCode,
        CampaignMessage,
        Checkbox,
    },
    data() {
        return {
            spinnerTrigger: betslip.action.PLACE_BET,
            isPresto: deviceType.isPresto(),
            isSmartphonePresto: deviceType.isSmartphonePresto(),
            refreshCountPresto: 0,
            copyButton: null,
            emergencyBlockBets: false,
            noLongerAvailableCode: NO_LONGER_AVAILABLE_CODE,
        };
    },
    computed: {
        isCashoutable() {
            const bets = this.betslip.bets.filter((bet) => bet.price.PriceRaw && !this.offlinePrices.includes(bet.price.Id));
            return !!bets.length && bets.every((bet) => bet.market.cashoutable === true);
        },
        placeholderInput() {
            return this.$t(`ui.betslip.${this.betting?.stakeDisplayType === stakeFormat.AMOUNT ? 'amount' : 'yourStake'}`);
        },
        spinnerDescription() {
            return this.$t(`ui.betslip.${this.betslip.pollingInterval ? 'placingBetTimer' : 'tryingToPlaceBet'}`, {
                seconds: helper.getObjectField(this.betslip, 'timer', 0),
            });
        },
        betInProgress() {
            return this.isLoading(betslip.action.PLACE_BET);
        },
        isOddsRefreshing() {
            return this.isLoading(betslip.action.GET_SELECTIONS_BY_IDS);
        },
        bookingInProgress() {
            return this.isLoading(betslip.action.CREATE_BOOKING_CODE);
        },
        pawaBonus() {
            return this.compensationBonus ? this.totalWin * this.compensationBonus : 0;
        },
        totalWin() {
            return this.totalWinnings + this.totalWinBonus;
        },
        compensationBonus() {
            return this.taxCompensate / (this.taxCompensate - this.taxRealAmount) - this.taxCompensate;
        },
        totalAmount() {
            let total = this.totalWin + this.stakeAfterTax;

            if (!this.taxCompensate) {
                total -= this.tax;
            }

            return this.$rounded(total);
        },
        controlsDisabled() {
            return !this.betslip.bets.length || this.isOddsRefreshing || this.betInProgress || this.emergencyBlockBets;
        },
        legBonusInfo() {
            if (!this.showLegInfo) {
                return;
            }
            const hasTax = this.isTaxEnabled && !this.isWht;
            let messageType;
            switch (this.currentBonusIndex) {
                case 0:
                    messageType = 'first';
                    break;
                case this.bonuses.length - 1:
                    messageType = 'last';
                    break;
                default:
                    messageType = 'middle';
            }

            return this.$t(`ui.betslip.legBonus.${hasTax ? 'grossTax' : 'noTax'}.${messageType}`, {
                ...this.legInfoData,
                moreSelectionSuffix: this.legInfoData.selectionsToNextTier === 1 ? ' ' : 's ',
                selectionSuffix: this.legInfoData.currentSelections === 1 ? ' ' : 's ',
                winBonus: this.$t(`ui.common.${this.countryCodeIs.KE ? 'plainWinBoost' : 'plainWinBonus'}`),
                minimumBonusOdds: this.$t(`ui.betslip.legBonus.${this.minimumOddsForBonus ? 'minimumBonusOdds' : 'noMinimumBonusOdds'}`, {
                    minimumBonusOdds: this.minimumOddsForBonus,
                }),
            });
        },
        taxAmount() {
            return this.isVirtualBetslip ? this.taxVirtualAmount : this.taxRealAmount;
        },
        taxableWinnings() {
            const { from } = this.taxTier || {};
            return from && this.taxableAmount - from;
        },
        taxableAmount() {
            let taxable = this.totalWinnings;
            if (this.totalWinBonus && !this.isMultiplierSchema) {
                taxable += this.totalWinBonus;
            }
            return taxable;
        },
        tax() {
            if (this.isTaxEnabled || this.isWinTaxEnabled) {
                let taxable = this.taxableAmount;
                switch (this.taxType) {
                    case whtTax.WHT_NET_TIERED:
                        const { from, rate } = this.taxTier;
                        return (taxable - from) * (rate || 0);
                    default:
                        if (GROSS_TAX_TYPES.includes(this.taxType)) {
                            taxable += this.stakeAfterTax;
                        }
                        return taxable * this.taxAmount;
                }
            } else {
                return 0;
            }
        },
        taxTier() {
            const taxMetaData = this.settings?.taxBrand?.real?.win;
            const taxVirtualMetaData = this.settings?.taxBrand?.virtual?.win;
            const taxRanges = (this.isVirtualBetslip ? taxVirtualMetaData?.rates : taxMetaData?.rates) || [];
            return taxRanges.find((range) => this.taxableAmount >= range.from && this.taxableAmount <= (range.to || Infinity));
        },
        stakeStep() {
            return 1 / Math.pow(10, this.betting.precision);
        },
        shouldJoin() {
            return !this.isAuthenticated && this.betslip.bets.length;
        },
        shouldDeposit() {
            return this.balance < this.betting.minStake || (this.error?.code && NOT_ENOUGH_MONEY_CODE.includes(this.error.code));
        },
        dynamicCTATranslations() {
            if (this.shouldJoin) {
                return this.$t('ui.betslip.loginToPlaceBet');
            }
            if (this.shouldDeposit) {
                return this.$t('ui.betslip.makeDeposit');
            }
            if (this.error.code === 'FOB_ODDS_CHANGED' && !this.acceptAnyPrice) {
                return this.$t('ui.betslip.acceptNewOdds');
            }
            return this.$t(`ui.betslip.${this.offlinePrices.length && !this.allPricesOffline ? 'placeBetWithoutNA' : 'placeBet'}`);
        },
        isCTADisabled() {
            if (this.shouldJoin || this.shouldDeposit) return false;
            return this.controlsDisabled || !this.stake || this.allPricesOffline;
        },
        stakeDecimalLength() {
            const [, decimal] = this.stake.toString().split('.');
            return decimal ? decimal.length : 0;
        },
        stakeDecimalError() {
            if (this.betting.precision > 0) {
                return this.$t('ui.betslip.error.stakeBetPrecision', { decimalBetPrecision: this.betting.precision });
            }
            return this.$t('ui.betslip.error.stakeIsDecimal');
        },
        currentTab() {
            return this.tabs.find(({ key }) => key === this.selectedType);
        },
        tabs() {
            return [
                {
                    text: this.$t('ui.betslip.sportTab'),
                    key: betslipType.REGULAR,
                    count: this.sportBetsCount,
                    iconId: 'icon-ball-with-shadow',
                    iconClass: 'icon-size-small',
                },
                {
                    text: this.$t('ui.betslip.virtualSportTab'),
                    key: betslipType.VIRTUAL,
                    count: this.virtualBetsCount,
                    iconId: 'icon-virtual-sport',
                    iconClass: 'icon-size-small',
                },
            ];
        },
        error() {
            return this.betslip.error;
        },
        season() {
            return this.$t('ui.betslip.season', { name: this.betslip.season.name });
        },
        selection() {
            const priceItems = [];
            const availableBets = [];
            let allMarketsCashoutable = true;
            for (const bet of this.betslip.bets) {
                if (bet.price.PriceRaw && !this.offlinePrices.includes(bet.price.Id)) {
                    priceItems.push({
                        selectionId: bet.price.Id,
                        price: bet.price.Price,
                        eligibleForBonus: bet.price.Price >= this.minimumOddsForBonus,
                    });
                    availableBets.push(bet);
                    allMarketsCashoutable = allMarketsCashoutable && bet.market.cashoutable;
                }
            }
            const pricesExist = !!priceItems.length;
            const cashoutable = allMarketsCashoutable && pricesExist && this.cashoutAvailable;

            return { availableBets, priceItems, cashoutable };
        },
        cashoutAvailable() {
            // TODO remove false lock
            return this.isCashoutAvailable && !this.isVirtualBetslip;
        },
        type() {
            return this.isVirtualBetslip ? betslipType.VIRTUAL : betslipType.REGULAR;
        },
        betting() {
            const { betting } = this.settings;
            return this.isVirtualBetslip ? betting.virtuals : betting.real;
        },
        showCampaignMessage() {
            const { campaignCheckEnabled } = this.brandPreferences;
            const { isEligible, eligibleUntil } = this.campaignEligibility || {};
            if (!campaignCheckEnabled || !isEligible) return false;
            return !!eligibleUntil;
        },
        ...mapState({
            isAdmin: (state) => state.platform.settings.user.isAdmin,
            legBonus: (state) => state.platform.settings.bonus,
            currency: (state) => state.platform.settings.currency,
            isCashoutAvailable: (state) => state.platform.settings.cashout.available,
            selectedType: (state) => state.sport.betslip.selectedType,
            createdBooking: (state) => state.sport.betslip.booking.created,
            betslipOpen: (state) => state.ui.betslipOpen,
        }),
        ...mapGetters({
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            balance: auth.getter.GET_BALANCE,
            totalOdds: betslip.getter.GET_TOTAL_ODDS,
            isLoading: coreGetter.IS_LOADING,
            settings: platformGetter.GET_SETTINGS,
            isMultiplierSchema: platformGetter.IS_MULTIPLIER_SCHEMA,
            isVirtualEnabled: platformGetter.IS_VIRTUAL_ENABLED,
            taxRealAmount: platformGetter.GET_TAX_AMOUNT,
            taxVirtualAmount: platformGetter.GET_VIRTUAL_TAX_AMOUNT,
            taxCompensate: platformGetter.GET_TAX_COMPENSATE,
            campaignEligibility: platformGetter.GET_CAMPAIGN_ELIGIBILITY,
            isUsingCampaignEligibility: platformGetter.GET_IS_USING_CAMPAIGN_ELIGIBILITY,
            brandPreferences: platformGetter.GET_BRAND_PREFERENCE,
            minimumOddsForBonus: platformGetter.GET_MINIMUM_ODDS_FOR_BONUS,
            sportProgressiveJackpot: platformGetter.GET_SPORT_PROGRESSIVE_JP_FROM_SETTINGS,
            // todo separate betslip props
            betslip: betslip.getter.GET_SELECTED_BETSLIP,
            isVirtualBetslip: betslip.getter.IS_VIRTUAL_BETSLIP,
            sportBetsCount: betslip.getter.GET_BETSLIP_BETS_COUNT,
            virtualBetsCount: betslip.getter.GET_VIRTUAL_BETSLIP_BETS_COUNT,
            acceptAnyPrice: betslip.getter.GET_ACCEPT_ANY_PRICE,
            offlinePrices: betslip.getter.GET_OFFLINE_PRICES,
            allPricesOffline: betslip.getter.ALL_PRICES_OFFLINE,

            // TODO: refactor in scope of BP-20682 ↓
            totalWinnings: betslip.getter.GET_TOTAL_WINNINGS,
            totalWinBonus: betslip.getter.GET_TOTAL_WIN_BONUS,
            currentBonus: betslip.getter.GET_CURRENT_BONUS,
            currentBonusIndex: betslip.getter.GET_CURRENT_BONUS_INDEX,
            bonuses: betslip.getter.GET_BONUSES,
            showLegInfo: betslip.getter.GET_SHOW_LEG_INFO,
            legInfoData: betslip.getter.GET_LEG_INFO_DATA,
            isTaxEnabled: betslip.getter.GET_IS_TAX_ENABLED,
            isWinTaxEnabled: betslip.getter.GET_IS_WIN_TAX_ENABLED,
            taxType: betslip.getter.GET_TAX_TYPE,
            isWht: betslip.getter.GET_IS_WHT,
            stakeAfterTax: betslip.getter.GET_STAKE_AFTER_TAX,
            multiplier: betslip.getter.GET_MULTIPLIER,
            multiplierTotalOdds: betslip.getter.GET_MULTIPLIER_TOTAL_ODDS,
            stake: betslip.getter.GET_STAKE,
            selectionIds: betslip.getter.GET_SELECTION_IDS,
            // TODO: refactor in scope of BP-20682 ↑
            placedBetslipTypes: betslip.getter.GET_PLACED_TYPES,
            countryCodeIs: platformGetter.COUNTRY_CODE_IS,
        }),
        isNAError() {
            return this.noLongerAvailableCode.includes(this.error?.code) || this.offlinePrices?.length;
        },
        isErrorVisible() {
            return !this.betInProgress && (this.error?.message || (this.isNAError && !this.shouldDeposit));
        },
    },
    methods: {
        ...mapActions({
            getSelectionsByIds: betslip.action.GET_SELECTIONS_BY_IDS,
            removeBet: betslip.action.REMOVE_BET,
            getCampaignEligibility: action.GET_CAMPAIGN_ELIGIBILITY,
        }),
        ...mapMutations({
            setStake: betslip.mutation.SET_BETSLIP_STAKE,
            resetBetslipStake: betslip.mutation.RESET_BETSLIP_STAKE,
            setAcceptAnyPrice: betslip.mutation.SET_BETSLIP_ACCEPT_ANY_PRICE,
            removeNotifications: storeMutation.CLEAR_NOTIFICATIONS,
        }),
        $interpolate: helper.interpolate,
        $numberFormat: helper.numberFormat,
        $rounded: helper.rounded,
        clickEvent({ event }) {
            if (event === 'resetSlip') {
                this.resetSlip();
            }
        },
        handleKeydownBetAmount(event) {
            const { key } = event;
            if (FORBIDDEN_CHARACTERS.includes(key) || this.isPointBlocked(key)) {
                return event.preventDefault();
            }
        },
        isPointBlocked(eventKey) {
            const { precision } = this.betting;
            if (precision > 0 && !ALLOWED_DECIMAL_SEPARATORS.includes(eventKey)) {
                return false;
            }
            return this.stake % 1 !== 0;
        },
        openBetSharingModal() {
            const betValid = this.verifyBet({
                skipStakeAmount: true,
                skipDecimal: true,
                skipMinStake: true,
                skipTotalAmount: true,
            });

            if (!betValid) {
                return;
            }

            this.$gtm.query({ event: 'bet_slip_booking_code_create' });
            const { availableBets } = this.selection;
            const selectionIds = availableBets.map((it) => it.price.Id);
            this.$store
                .dispatch(betslip.action.LOAD_SHARE_LINKS, {
                    selectionIds,
                    textKey: 'social.preBetShareText',
                })
                .finally(() => {
                    this.$modal.show('bet-sharing-modal');
                    prestoScrollTop(this.$router);
                });
        },
        onAcceptChange(value) {
            if (!this.isAuthenticated) return;

            this.$gtm.query({
                event: 'user_accept_odd_change',
                accepted: value,
            });
        },
        placeBet() {
            if (!this.isAuthenticated) {
                this.$gtm.query({ event: 'login_to_place_bet' });
                this.checkRouteAndRedirect(betslipRedirects.LOGIN, postAuthenticationRouteQueries.openBetslip);
                return;
            }
            if (this.shouldDeposit) {
                this.$gtm.query({ event: 'make_deposit_to_place_bet' });
                this.checkRouteAndRedirect(betslipRedirects.DEPOSIT);
                return;
            }

            const betValid = this.verifyBet();
            if (this.error.code === 'FOB_ODDS_CHANGED' && !this.acceptAnyPrice) {
                this.$gtm.query({ event: 'accept_new_odds_and_place_bet' });
            }
            if (!betValid) {
                return;
            }

            if (!this.isVirtualBetslip) {
                const isAcceptOdds = this.$refs.priceAccept.isChecked;
                this.setAcceptAnyPrice(isAcceptOdds);
                const data = { key: 'accept_odds', value: isAcceptOdds };
                this.$store.dispatch(action.PUT_DERIVED_DATA, data);
            }

            const { availableBets, priceItems, cashoutable } = this.selection;

            const notEligibleForBonus = this.totalOdds < this.legBonus.minimumTotalOdds;

            // TODO: remove useNewCampaignCoupons field https://aliengain.atlassian.net/browse/BP-30936
            const { useNewCampaignCoupons } = this.brandPreferences;
            const shouldHaveCampaignUuid = this.showCampaignMessage && useNewCampaignCoupons && this.isUsingCampaignEligibility;
            const campaignUuid = shouldHaveCampaignUuid ? this.campaignEligibility.campaignUuid : null;

            this.$store
                .dispatch(betslip.action.PLACE_BET, {
                    bet: {
                        stake: this.stake,
                        legBonusSchemeId: this.legBonus.id,
                        items: priceItems,
                        stakeFormat: this.betting?.stakeDisplayType,
                        acceptAnyPrice: this.acceptAnyPrice,
                        userState: {
                            cashoutable: cashoutable,
                            eligibleForBonus: !notEligibleForBonus,
                        },
                        ...(this.showCampaignMessage && { campaign: campaignUuid }),
                    },
                    type: this.type,
                })
                .catch(({ errorCode }) => {
                    if (ELIGIBLE_ODDS_CHANGED_ERROR_CODE.includes(errorCode)) {
                        this.getSelectionsByIds({ selections: this.betslip.selectedBetsIds, type: this.selectedType });
                    }
                });
            this.$store.commit(betslip.mutation.SET_BETSLIP_BETS, { bets: availableBets, type: this.type });
            android.betPlaced('sport');
            this.removeNotifications();
            this.$store.commit(platformMutation.SET_CAMPAIGN_ELIGIBILITY, {});
            this.$store.commit(platformMutation.SET_USING_CAMPAIGN_ELIGIBILITY, false);
        },
        removeNotAvailableBets() {
            for (const bet of this.betslip.bets) {
                if (!bet.price.PriceRaw) {
                    this.removeBet(bet);
                }
            }
        },
        verifyBet(config = {}) {
            if (!config.skipHasBets && !this.betslip.bets.length) {
                return this.handleBetVerificationError({
                    message: this.$t('ui.betslip.error.atLeastOne'),
                    reportError: 'error-slip-is-empty',
                });
            }
            if (!config.skipStakeAmount && this.stake > this.balance) {
                return this.handleBetVerificationError({
                    message: this.$t('ui.betslip.error.notEnoughMoney'),
                    reportError: 'error-insufficient-funds',
                    gtmEvent: 'balance_lower_stake',
                });
            }
            if (!config.skipDecimal && this.stakeDecimalLength > this.betting.precision) {
                return this.handleBetVerificationError({
                    message: this.stakeDecimalError,
                    reportError: 'error-stake-is-decimal',
                });
            }
            if (!config.skipMinStake && this.stake < this.betting.minStake) {
                return this.handleBetVerificationError({
                    message: this.$t('ui.betslip.error.minStake', { minStake: this.betting.minStake, code: this.currency.code }),
                    reportError: 'error-stake-too-small',
                });
            }
            if (!config.skipMaxItems && this.betslip.bets.length > this.betting.maxItems) {
                return this.handleBetVerificationError({
                    message: this.$t('ui.betslip.error.maxBetsNumberExceeded', { maxBetsNumber: this.betting.maxItems }),
                    reportError: 'error-too-many-items',
                });
            }
            if (!config.skipTotalAmount && !this.isTaxEnabled && Number(this.stake) > Number(this.totalAmount)) {
                return this.handleBetVerificationError({
                    message: this.$t('ui.betslip.error.payoutLowerThanStake'),
                    reportError: 'error-payout-less-equals-than-stake',
                });
            }
            if (this.error.message) {
                this.$store.dispatch(betslip.action.RESET_BETSLIP_ERROR, this.type);
            }

            return true;
        },
        handleBetVerificationError({ message, reportError, gtmEvent }) {
            this.$store.dispatch(betslip.action.SET_BETSLIP_ERROR, {
                message,
                type: this.type, // reactor BP-16141
            });
            this.sendErrorReport(reportError);

            if (gtmEvent) {
                this.$gtm.query({ event: gtmEvent });
            }

            return false;
        },
        resetSlip(args = {}) {
            const { track, resetAllPlaced } = args;
            const errorCode = this.error.code;
            if (resetAllPlaced) {
                this.placedBetslipTypes.forEach((bType) => {
                    this.resetBetslipStake(bType);
                    this.$store.dispatch(betslip.action.REMOVE_ALL_BETS, bType); // remove param BP-16141
                });
            } else {
                this.resetBetslipStake(this.type);
                this.$store.dispatch(betslip.action.REMOVE_ALL_BETS, this.type); // remove param BP-16141
            }
            if (ELIGIBLE_ODDS_CHANGED_ERROR_CODE.includes(errorCode)) {
                window.location.reload();
            }
            if (track) {
                this.$gtm.query({ event: 'bet_clear' });
            }
        },
        refreshBetslipPresto() {
            if (this.refreshCountPresto < 12) {
                this.$store.dispatch(betslip.action.GET_BETSLIP_STATUS, {
                    attemptId: this.betslip.status.betslipId || this.betslip.status.attemptId,
                    type: this.type,
                });
            } else {
                this.$store.dispatch(betslip.action.SET_BETSLIP_ERROR, {
                    message: this.$t('ui.betslip.error.timeout'),
                    type: this.type,
                });
            }
        },
        sendErrorReport(error) {
            const hasLiveEvents = this.betslip.bets.some((event) => event.isLive);
            this.$gtm.query({
                event: 'bet_place_error',
                reason: error,
                status: hasLiveEvents ? 'live' : 'pre-live',
                bet_type: this.type,
            });
        },
        setActiveTab({ key }) {
            this.$store.commit(betslip.mutation.SET_SELECTED_BETSLIP, key);
        },
        checkCampaignEligibility() {
            if (!this.isAuthenticated || !this.betslip.bets.length) return;
            const { campaignCheckEnabled } = this.brandPreferences;
            const isLoading = this.isLoading(action.GET_CAMPAIGN_ELIGIBILITY);
            if (campaignCheckEnabled && !isLoading) {
                this.getCampaignEligibility();
            }
        },
        checkRouteAndRedirect(name, postAuthRouteQuery = null) {
            if (this.$route.name === name && JSON.stringify(this.$route.query) === JSON.stringify(postAuthRouteQuery)) {
                this.closeBetslip();
                return;
            }

            let routeObj = { name };
            if (postAuthRouteQuery) routeObj.query = postAuthRouteQuery;
            this.$router.push(routeObj);
        },
        trackLinkClick() {
            this.closeBetslip();
            this.$gtm.query({
                event: 'betslip_join_click',
                click_element: 'link',
            });
        },
        closeBetslip() {
            this.betslipOpen && this.$store.dispatch(storeAction.SET_BETSLIP_STATE, false);
        },
    },
    watch: {
        // Move tracking to the store and delete the watcher
        'betslip.status'(status) {
            if (this.betInProgress) {
                return;
            }
            if (status.Placed) {
                const { priceItems } = this.selection;
                this.$gtm.query({
                    event: 'bet_placed',
                    items: priceItems.length,
                    odds: this.multiplierTotalOdds,
                    bet_amount: this.stake,
                    bet_type: this.type,
                });
                this.resetBetslipStake(this.type);
            }
        },
        'betslip.status.Placed'(value) {
            if (value) {
                this.$scroll.scrollTo(0);
            }
        },
        'betslip.bets'(value, prevValue) {
            if (prevValue.length < value.length) {
                this.checkCampaignEligibility();
            }
        },
        $route() {
            this.removeNotAvailableBets();
            if (this.placedBetslipTypes.length > 0) {
                this.resetSlip({ resetAllPlaced: true });
            }
        },
        error(error) {
            if (SERVER_BUSY_ERROR_CODE.includes(error.code) && !deviceType.isPresto()) {
                this.emergencyBlockBets = true;
                setTimeout(() => (this.emergencyBlockBets = false), 3000);
            }
        },
        isAuthenticated() {
            this.checkCampaignEligibility();
        },
    },
    beforeDestroy() {
        if (this.placedBetslipTypes.length > 0) {
            this.resetSlip({ resetAllPlaced: true });
        }
    },
    created() {
        this.checkCampaignEligibility();
        if (!this.isLoading(betslip.action.LOAD_PRESTO_BETSLIP)) {
            this.$store.dispatch(betslip.action.LOAD_PRESTO_BETSLIP);
        }
    },
};
</script>

<style scoped lang="scss">
.notify {
    text-align: left;
}

.betslip-main {
    overflow: auto;
    position: relative;

    .tab-counter {
        margin-left: 4px;
        width: 16px;
        height: 16px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 10px;
        border-radius: 50%;
        background: $medium-grey;

        &.active {
            background: $message-success;
        }
    }

    .empty-betslip {
        text-align: center;
        margin: 0 10px 10px;

        &-title {
            @extend %body-normal-font-700;
            color: $main-text;
            margin-top: 15px;
        }

        .svg-icon {
            margin: 35px 0;
            width: 100%;
            height: auto;
            max-height: 90px;
        }
    }

    &.placed-betslip {
        @include all_but_mini {
            flex: 1;
            display: flex;
            flex-direction: column;
            overflow: hidden;
        }
    }
}

.betslip-bottom {
    padding: 8px 8px 24px;
    background: $betslip-bottom-background-color;
    border-top: 1px solid $betslip-bottom-border-color;

    &.virtual {
        background: $betslip-jackpot-bottom-background-color;
    }
}

.betslip-form {
    margin-top: 12px;
}

.clear-slip {
    height: 22px;
    font-size: 14px;
    text-align: right;
    color: $grey-text;
}

.accept-change {
    padding: 2px 4px 0 4px;
    color: $grey-text;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;

    .accept-price-button {
        margin-left: 6px;
    }
}

.action-buttons {
    margin-top: 16px;
}

.betslip-toolbar {
    @extend %body-normal-font-400;

    .booking-code-link {
        svg {
            height: 16px;
            width: 16px;
            margin-right: 6px;
        }

        &:hover,
        &:active {
            svg {
                fill: $primary-color;
            }
        }
    }
}

.icon-not-available {
    fill: $grey-text;
}

.betslip-tools {
    margin-top: 10px;

    &:after {
        content: '';
        display: table;
        clear: both;
    }
}

.cashout-availability {
    color: $disabled-text;
    display: inline-block;
}

.available {
    color: $cashout-offer-available;
}

.cashout-availability-description {
    @extend %body-normal-font-400;
    margin-left: 7px;
}

.icon-cashoutable {
    width: 20px;
    height: 16px;
    fill: currentColor;
}

.admin {
    overflow-wrap: break-word;
}

.join-now-text {
    @extend %small-details-font-400;
    text-align: center;
    margin-top: 16px;
}

small {
    @extend %body-normal-font-400;
    display: inline-block;
    width: 100%;
    text-align: center;
    padding: 12px 0 0;
}

.place-bet.button {
    white-space: normal;
}
</style>
