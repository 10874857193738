<template>
    <div id="app" v-onmedia="handleMenus" :class="deviceTypesArray">
        <a v-if="isPresto" id="top" />
        <AppErrorPage v-if="settings && settings.error" />
        <LoadingPage v-else :listen="spinnerTrigger" :visible="!appIsReady || isBookingCodeLoading" />
        <LandingPage v-if="!settings && !settings.brand" />
        <template v-else-if="settings && appIsReady">
            <AppLayout v-if="!isPresto" :is-center-overlay-active="false" :disableScroll="$mq.isXMedium && isLeftSidebarOpen">
                <template v-slot:section:top>
                    <Header />
                    <ProductNav />
                </template>
                <template v-slot:section:left>
                    <SideBar
                        :width="$mq.isXXMediumMin ? '30vw' : $mq.isXMedium ? '100vw' : undefined"
                        :class="{ mobile: $mq.isXMedium }"
                        className="leftMenu"
                        :isMobile="$mq.isXMedium"
                        :isOverlaps="isLeftSidebarOverlaps"
                        :isOpen="isLeftSidebarOpen"
                        :hasTransitionDelay="isBottomNavigationVisible"
                        canClose
                        v-on:onRouteChange="leftMenuRouteChange"
                    >
                        <LeftMenu />
                    </SideBar>
                </template>
                <template v-slot:section:center:top>
                    <EventFilter v-if="showEventFilters" />
                    <ChipsHeader v-if="showChipsHeader" />
                    <div v-for="{ type, active } in displayedProgressiveJackpots" :key="type">
                        <ProgressiveJpProvider v-if="active" :type="type">
                            <template v-slot="{ progressiveJackpot }">
                                <ProgressiveJpPreviewBarCollapsible v-bind="{ progressiveJackpot }" />
                            </template>
                        </ProgressiveJpProvider>
                    </div>
                </template>
                <template v-slot:section:center:middle>
                    <div class="view-wrapper-overlay" v-if="isGlobalLoadingInProgress">
                        <Spinner class="align-center transparent" :visible="true" />
                    </div>
                    <OnSiteMessages v-if="isAuthenticated && $mq.isXMedium" />
                    <Notifications />
                    <Announcement />
                    <OnBoarding v-if="isHomePage" />
                    <ChipsNotification v-if="showChips && !toppedUpChips" />
                    <ChipsModal v-if="showChips" />
                    <BetSharingModal />
                    <TermsAndConditions v-if="appIsReady && showTermsAndConditions" :date="TERMS_DATE_UPDATE" />
                    <div class="main-content-2">
                        <template v-if="settings && !settings.error">
                            <router-view class="router-view" :showEventFilters="showEventFilters" />
                        </template>
                    </div>
                    <Footer />
                    <VersionApp class="version-app-footer" />
                </template>
                <template v-slot:section:right>
                    <SideBar
                        class=""
                        :right="true"
                        :class="{ mobile: $mq.isXMedium }"
                        :isFullScreen="$mq.isXMedium"
                        :isMobile="$mq.isXMedium"
                        :isOverlaps="$mq.isXMedium"
                        :isOpen="isRightSidebarOpen"
                        :hasTransitionDelay="isBottomNavigationVisible"
                        :canClose="$mq.isXMedium"
                    >
                        <BetSideBar />
                    </SideBar>
                </template>
                <template v-slot:section:bottom>
                    <BottomNavigation v-if="isBottomNavigationVisible" :hide="isRightSidebarOpen || isLeftSidebarOpen" />
                    <OpenBetslip iconId="arrow_left" v-if="betslipBarEnabled" />
                </template>
            </AppLayout>
            <AppLayoutPresto
                v-else
                :show-terms-and-conditions="showTermsAndConditions"
                :betslip-open="betslipOpen"
                :sidebar-open="sidebarOpen"
                :is-right-sidebar-open="isRightSidebarOpen"
            >
                <template v-slot:section:top>
                    <ProgressiveJpProvider v-if="isHomePage">
                        <template v-slot="{ progressiveJackpot }">
                            <ProgressiveJpPreviewBarPresto v-bind="{ progressiveJackpot }" />
                        </template>
                    </ProgressiveJpProvider>
                </template>
                <template v-slot:section:terms>
                    <TermsAndConditions v-if="appIsReady && showTermsAndConditions" :date="TERMS_DATE_UPDATE" />
                </template>
                <template v-slot:section:left>
                    <SideBar
                        :width="$mq.isXXMediumMin ? '30%' : $mq.isXMedium ? '100%' : '15%'"
                        :class="{ mobile: $mq.isXMedium }"
                        className="leftMenu"
                        :isMobile="$mq.isXMedium"
                        :isOpen="isLeftSidebarOpen"
                        :hasTransitionDelay="isBottomNavigationVisible"
                        canClose
                        v-on:onRouteChange="leftMenuRouteChange"
                    >
                        <LeftMenu />
                    </SideBar>
                </template>
                <template v-slot:section:center>
                    <OnSiteMessages v-if="isAuthenticated && $mq.isXMedium" />
                    <Notifications />
                    <Announcement />
                    <BetSharingModal />
                    <OnBoarding v-if="isHomePage" />
                    <template v-if="settings && !settings.error">
                        <router-view class="router-view" />
                    </template>
                </template>
                <template v-slot:section:right>
                    <SideBar
                        :width="$mq.isXMedium ? '100%' : '39%'"
                        :right="true"
                        :class="{ mobile: $mq.isXMedium }"
                        :isMobile="$mq.isXMedium"
                        :isOpen="isRightSidebarOpen"
                        :hasTransitionDelay="isBottomNavigationVisible"
                        :canClose="$mq.isXMedium"
                    >
                        <BetSideBar />
                        <BetSharingModal />
                    </SideBar>
                </template>
                <template v-slot:section:center:bottom>
                    <Footer />
                    <VersionApp class="version-app-footer" />
                </template>
                <template v-slot:section:bottom>
                    <BottomNavigation v-if="isBottomNavigationVisible" :hide="isRightSidebarOpen || isLeftSidebarOpen" />
                </template>
            </AppLayoutPresto>
        </template>
        <template v-if="!isPresto">
            <component
                v-if="liveChatEnabled && brandIdentifier"
                :is="liveChat"
                :brandIdentifier="brandIdentifier"
                :mode="liveChatMode"
                :phone="phone"
                username=""
            />
        </template>
    </div>
</template>

<style src="./styles/styles.scss" lang="scss"></style>

<script>
import { mapState, mapGetters } from 'vuex';
import { android, ios, config, deviceType, deviceTypesArray, getter as coreGetter } from '@agi.packages/core';
import { getObjectField } from '@agi.packages/core/utils/helper';
import { progressiveJpType } from '@agi.packages/platform/const/progressive-jackpot';
import { Spinner } from '@agi.packages/core/components';
import { BetSideBar, OpenBetslip, EventFilter } from '@agi.packages/sport/components';

import { betslip } from '@agi.packages/sport';
import { getter, action as platformAction, auth, isSibling, mutation as platformMutation } from '@agi.packages/platform';

import AppLayout from '@/components/Layout/AppLayout.vue';
import AppLayoutPresto from '@/components/Layout/AppLayoutPresto.vue';
import Header from '@/components/Header.vue';
import ProductNav from '@/components/ProductNav.vue';
import LandingPage from '@/components/Pages/LandingPage.vue';
import LoadingPage from '@/components/Pages/LoadingPage.vue';
import AppErrorPage from '@/components/Pages/AppErrorPage.vue';
import Footer from '@/components/Fragments/Footer.vue';
import LeftMenu from '@/components/Fragments/LeftMenu.vue';
import SideBar from '@/components/Fragments/SideBar.vue';
import Notifications from '@/components/Notifications.vue';
import VersionApp from '@/components/VersionApp';
import OnBoarding from '@/components/OnBoarding.vue';
import ChipsHeader from '@/components/Fragments/Chips/ChipsHeader.vue';
import ChipsModal from '@/components/Fragments/Chips/ChipsModal.vue';
import ChipsNotification from '@/components/Fragments/Chips/ChipsNotification.vue';
import ErrorPage from '@/components/Pages/ErrorPage';
import TermsAndConditions from '@/components/TermsAndConditions';
import BottomNavigation from '@/components/Fragments/BottomNavigation';

import { Announcement, OnSiteMessages } from '@agi.packages/platform/components';

import { action as storeAction, getter as generalGetter } from '@/store/store';
import { routeName } from '@/router/const-name';
import BetSharingModal from '@/modules/sport/components/Fragments/BetSharingModal.vue';
import ProgressiveJpProvider from '@/modules/platform/components/ProgressiveJpProvider.vue';
import ProgressiveJpPreviewBarCollapsible from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpPreviewBarCollapsible.vue';
import ProgressiveJpPreviewBarPresto from '@/components/Fragments/ProgressiveJackpot/ProgressiveJpPreviewBarPresto.vue';

const INITIAL_LOAD_SLOTS = ['LOGO_SUBTEXT', 'FOOTER_COMPONENT'];
const TERMS_DATE_UPDATE = new Date('2022-09-26T00:00:00.000Z');

export default {
    name: 'App',
    components: {
        ProgressiveJpPreviewBarPresto,
        ProgressiveJpPreviewBarCollapsible,
        ProgressiveJpProvider,
        BetSharingModal,
        AppLayout,
        AppLayoutPresto,
        Header,
        ProductNav,
        LandingPage,
        Footer,
        BetSideBar,
        LeftMenu,
        SideBar,
        Spinner,
        Notifications,
        Announcement,
        VersionApp,
        OnBoarding,
        TermsAndConditions,
        OnSiteMessages,
        LoadingPage,
        AppErrorPage,
        ErrorPage,
        OpenBetslip,
        BottomNavigation,
        ChipsHeader,
        ChipsModal,
        ChipsNotification,
        EventFilter,
    },
    data() {
        return {
            deviceTypesArray: deviceTypesArray(),
            appIsReady: null,
            spinnerTrigger: platformAction.LOAD_AGI_SETTINGS,
            isPresto: deviceType.isPresto(),
            isIos: deviceType.isIos(),
            canonicalLinkRef: null,
            TERMS_DATE_UPDATE,
        };
    },
    computed: {
        liveChat() {
            return () => {};
            // return () => import('@betpawa/live-chat')
        },
        ...mapState({
            sidebarOpen: (state) => state.ui.sidebarOpen,
            betslipOpen: (state) => state.ui.betslipOpen,
            isGlobalLoadingInProgress() {
                return this.isLoading(storeAction.LOADING);
            },
            countries: (state) => state.countries,
            mobileSearchOpen: (state) => state.ui.mobileSearchOpen,
        }),
        ...mapGetters({
            getContentGetter: getter.GET_CONTENT_SLOTS,
            isLoading: coreGetter.IS_LOADING,
            isAuthenticated: auth.getter.IS_AUTHENTICATED,
            preference: getter.GET_PREFERENCE,
            user: getter.GET_USER_SETTINGS,
            country: getter.GET_COUNTRY,
            settings: getter.GET_SETTINGS,
            brandDetails: getter.GET_BRAND_DETAILS,
            brandPreference: getter.GET_BRAND_PREFERENCE,
            currentCategoryId: generalGetter.GET_CURRENT_CATEGORY_ID,
            socketsUrl: getter.GET_SOCKETS_URL,
        }),
        isRightSidebarOpen() {
            return this.betslipOpen || this.$mq.isXXMediumMin;
        },
        isLeftSidebarOpen() {
            return this.sidebarOpen;
        },
        isLeftSidebarOverlaps() {
            return !this.isPresto;
        },
        isBottomNavigationVisible() {
            return this.bottomNavigationEnabled && !this.isPresto && this.$mq.isXMedium;
        },
        isHomePage() {
            return this.$route.name === routeName.HOMEPAGE;
        },
        isCasinoPage() {
            return [routeName.CASINO, routeName.GAMES].includes(this.$route.name);
        },
        toppedUpChips() {
            return this.preference.topped_up_chips;
        },
        showChips() {
            return this.isAuthenticated && this.settings.chips && this.isCasinoPage;
        },
        showChipsHeader() {
            return this.showChips && this.toppedUpChips;
        },
        showTermsAndConditions() {
            const termsAcceptedDate = this.preference['terms_accepted_date'] ? new Date(this.preference['terms_accepted_date']) : null;

            const userSignedUpAfterTermsUpdate = new Date(this.user.signedUp).getTime() < TERMS_DATE_UPDATE.getTime();
            const isAcceptedCurrentTerms = termsAcceptedDate && termsAcceptedDate.getTime() > TERMS_DATE_UPDATE.getTime();
            const { displayTermsConditions } = this.brandPreference;

            return this.isAuthenticated && !isAcceptedCurrentTerms && userSignedUpAfterTermsUpdate && displayTermsConditions;
        },
        bottomNavigationEnabled() {
            const { enableBottomNavigation } = this.brandPreference;
            return !!enableBottomNavigation;
        },
        betslipBarEnabled() {
            return !this.bottomNavigationEnabled && this.$mq.isXMedium && !this.isPresto && !this.betslipOpen;
        },
        brandIdentifier() {
            return this.settings?.brand?.identifier;
        },
        phone() {
            return this.user?.phoneNumber;
        },
        liveChatMode() {
            return config.isProd() ? 'production' : 'stage';
        },
        liveChatEnabled() {
            const { liveChat } = this.brandPreference;
            return !!liveChat;
        },
        showEventFilters() {
            const { meta } = this.$route;
            const { isEventPageFiltersEnabled } = this.brandPreference;
            return !!isEventPageFiltersEnabled && getObjectField(meta, 'showEventFilters') && !this.isPresto;
        },
        isBookingCodeLoading() {
            return this.isLoading(betslip.action.LOAD_BOOKING_CODE_BY_QUERY);
        },
        displayedProgressiveJackpots() {
            return [
                { type: progressiveJpType.SPORT, active: this.isHomePage },
                { type: progressiveJpType.CASINO, active: this.isCasinoPage },
            ];
        },
    },
    methods: {
        leftMenuRouteChange() {
            this.$store.dispatch(storeAction.SET_SIDEBAR_STATE, false);
        },
        handleMenus(alias, matches) {
            if (matches) {
                this.$store.dispatch(storeAction.SET_BETSLIP_STATE, alias === 'isXXMediumMin');
            }
        },
        setCanonicalLinkTag() {
            this.canonicalLinkRef = document.createElement('link');
            this.canonicalLinkRef.rel = 'canonical';
            this.canonicalLinkRef.href = this.getProdUrl();
            document.head.appendChild(this.canonicalLinkRef);
        },
        getProdUrl(path = '/', domain = this.brandDetails?.rootDomain?.toLowerCase()) {
            return `https://www.${domain}${path}`;
        },
        disconnectSocket() {
            this.$rs.disconnect();
        },
        resetUser() {
            this.$store.dispatch(auth.action.RESET_USER).then(() => {
                const { requireAuth, redirect } = this.$route.meta;
                if (requireAuth && redirect) {
                    const redirectRoute = typeof redirect === 'function' ? redirect({ to: this.$route }) : redirect;
                    this.$router.push(redirectRoute);
                }
            });
        },
    },
    created() {
        this.$http.interceptors.response.use(
            (response) => response,
            (error) => {
                if (error && error.statusCode === 401) {
                    this.resetUser();
                }
                return Promise.reject(error);
            }
        );
        this.setCanonicalLinkTag();
        if (this.isIos) {
            ios.setup();
        }
    },
    beforeMount() {
        window.addEventListener('beforeunload', this.disconnectSocket);
    },
    watch: {
        socketsUrl(url) {
            if (!this.isPresto && url) {
                this.$rs.connect(url);
            }
        },
        currentCategoryId: {
            immediate: true,
            handler(value, old) {
                // TODO: align variable types https://aliengain.atlassian.net/browse/BP-19617
                if (`${value}` !== `${old}` && this.appIsReady) {
                    this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, value);
                }
            },
        },
        settings() {
            if (!this.appIsReady) this.appIsReady = !this.appIsReady;

            if (!this.countries?.length) {
                this.$store.dispatch(storeAction.GET_CATEGORY_ACTIVE_REGIONS, this.currentCategoryId);
            }
        },
        appIsReady(val) {
            if (val) {
                android.siteRdy();
            }
        },
        $route(newRoute, oldRoute) {
            this.$store.commit('closeMobileSearch');
            this.canonicalLinkRef.href = this.getProdUrl(newRoute.path);
            if (!isSibling(newRoute.matched, oldRoute.path)) {
                const slotId = newRoute.matched[0].props.default && newRoute.matched[0].props.default.slotId;
                const slotIds = Array.isArray(slotId) ? slotId : (slotId && [slotId]) || [];
                this.$store.dispatch(platformAction.GET_CONTENT, [...INITIAL_LOAD_SLOTS, ...slotIds]);
                this.$store.commit(platformMutation.CLEAR_ACTIVE_PROGRESSIVE_JACKPOT_POLLING);
            }
        },
    },
};
</script>

<style lang="scss">
#app {
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    &.presto {
        display: table;
    }
}

.view-wrapper-overlay {
    position: fixed;
    background: rgb(0 0 0 / 60%);
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    flex: 1 1 auto;
    z-index: 999;
}

.stop-scroll {
    overflow: hidden;
}

@mixin presto-height($size) {
    @media screen and (min-height: $size) {
        min-height: $size;
    }
}

.main-content {
    width: 100%;
    display: flex;
    flex: 1 0 83%;
    @include only_mini {
        display: table;
        flex: 1;
        $sizes: 80px, 100px, 120px, 140px, 160px, 180px;
        @each $size in $sizes {
            @include presto-height($size);
        }
    }
    &-2 {
        flex: 1 0 $main-content-min-height;
    }
}

.router-view {
    position: relative;
}

.center-view {
    position: relative;
    overflow: hidden;
    flex: 1;
    width: 100%;
}

.side-bar.leftMenu {
    flex: 1 0 17%;
}
</style>
