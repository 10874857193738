<template>
    <component
        :is="component"
        v-bind="componentAttributes"
        :class="['highlight-hover-disabled', 'progressive-jp', `progressive-jp-${type}`, `progressive-jp-${mode}`]"
        @[clickEventName].native="trackClickReadMore"
    >
        <img alt="" :src="background" class="progressive-jp-background" />
        <span class="progressive-jp-top">
            <span v-if="participant">
                <SvgIcon iconId="icon-circle-checked-blank" class="icon-size-very-small" verticalAlign="baseline" />
                <span>{{ $t('ui.jackpot.youAreIn') }}</span>
            </span>
            <span>{{ name }}</span>
            <span class="progressive-jp-drops upperCase">{{ frequencyText }}</span>
            <span v-if="tax && !isFull && !participant">{{ cta }}</span>
            <router-link
                v-if="isFull && readMoreRoute"
                class="underline progressive-jp-read-more"
                :to="readMoreRoute"
                @click.native.stop="trackClickReadMore"
            >
                {{ $t('ui.betslip.readMore') }}
            </router-link>
        </span>
        <div :class="{ 'progressive-jp-prize': true, 'progressive-jp-prize-tax': !!tax }">
            <Currency :amount="prize" :format="currencyFormat" :decimal="0" />
        </div>
        <div class="progressive-jp-bottom">
            <span class="progressive-jp-tax" v-if="tax">{{ $t('ui.pjp.tax', { tax }) }}</span>
            <template v-if="!isFull">
                <span v-if="!tax && !participant">{{ cta }}</span>
                <router-link
                    v-if="readMoreRoute"
                    class="underline progressive-jp-read-more"
                    :to="readMoreRoute"
                    @click.native.stop="trackClickReadMore"
                >
                    {{ $t('ui.betslip.readMore') }}
                </router-link>
            </template>
        </div>
    </component>
</template>

<script>
import { Currency } from '@/modules/core/components';
import { mapState } from 'vuex';
import { progressiveJpTypes } from '@/modules/platform/const/progressive-jackpot';

const modeType = {
    FULL: 'full',
    COMPACT: 'compact',
};

const componentTag = {
    LINK: 'router-link',
    COMMON: 'div',
};

export default {
    name: 'ProgressiveJpContent',
    components: { Currency },
    props: {
        name: {
            type: String,
        },
        frequency: {
            type: String,
        },
        prize: {
            type: Number,
        },
        tax: {
            type: Number,
        },
        link: {
            type: String,
        },
        background: {
            type: String,
        },
        cta: {
            type: String,
        },
        type: {
            type: String,
            validator(val) {
                return progressiveJpTypes.includes(val);
            },
        },
        mode: {
            type: String,
            validator(val) {
                return Object.values(modeType).includes(val);
            },
            default: () => modeType.COMPACT,
        },
        clickDisabled: {
            type: Boolean,
            default: false,
        },
        participant: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapState({
            currencyFormat: (state) => state.platform.settings.currency.format,
        }),
        frequencyText() {
            return this.$t('ui.pjp.drops', { value: this.$t(`ui.pjp.frequency.${this.frequency.toLowerCase()}`) });
        },
        isFull() {
            return this.mode === modeType.FULL;
        },
        component() {
            return !this.clickDisabled && this.readMoreRoute ? componentTag.LINK : componentTag.COMMON;
        },
        isClickable() {
            return this.component === componentTag.LINK;
        },
        readMoreRoute() {
            if (this.isReadMorePage) return null;
            return { path: this.link };
        },
        componentAttributes() {
            return {
                ...(this.isClickable && { to: { ...this.readMoreRoute } }),
            };
        },
        isReadMorePage() {
            return this.$route.fullPath === this.link;
        },
        clickEventName() {
            return this.isClickable ? 'click' : null;
        },
    },
    methods: {
        trackClickReadMore() {
            this.$gtm.query({ event: 'progressive_jp_read_more_click' });
        },
    },
};
</script>

<style lang="scss" scoped>
@mixin bullet($color) {
    background-image: url('data:image/svg+xml,<svg width="4" height="4" viewBox="0 0 4 4" xmlns="http://www.w3.org/2000/svg"><circle cx="2" cy="2" r="2" fill="%23#{$color}"/></svg>');
    background-repeat: no-repeat;
    background-position: center;
}

.progressive-jp {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    padding: 4px 12px;
    position: relative;

    &-background {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: top;
        z-index: -1;
    }

    &-sport {
        color: map-get($pjp-color-schema, 'sport', 'text');
    }

    &-casino {
        color: map-get($pjp-color-schema, 'casino', 'text');
    }

    &-full {
        padding: 8px 12px;
        min-height: 105px;
    }

    &-top {
        @extend %small-details-font-400;
        @include maxoldschoolandmini {
            font-size: $footnote-caption-font-size;
        }

        svg {
            margin-right: 4px;

            .progressive-jp-casino & {
                fill: map-get($pjp-color-schema, 'casino', 'text');
            }
        }
    }
    &-bottom {
        @extend %caption-font-400;
        margin-top: 2px;
    }
    &-top,
    &-bottom {
        span {
            &:not(:last-child)::after {
                content: ' ';
                text-align: center;
                letter-spacing: 12px;

                .progressive-jp-sport & {
                    @include all_but_mini {
                        @include bullet('6BBB0D');
                    }
                }

                .progressive-jp-casino & {
                    @include all_but_mini {
                        @include bullet('6a87de');
                    }
                }
            }
        }
    }
    &-drops {
        @extend %small-details-font-700;
        @include maxoldschoolandmini {
            font-size: $footnote-caption-font-size;
        }
    }
    &-prize {
        .currency {
            @extend %h1-font-700;
            font-size: 27px;
            text-transform: uppercase;

            @include maxoldschoolandmini {
                font-size: $h3-font-size;
            }

            .progressive-jp-sport & {
                color: map-get($pjp-color-schema, 'sport', 'prize');
            }

            .progressive-jp-casino & {
                text-shadow: 0px 1px 0px map-get($pjp-color-schema, 'casino', 'shadow');
                color: map-get($pjp-color-schema, 'casino', 'prize');
            }
        }

        &::after {
            content: '';
            display: block;
            width: 95%;
            height: 1px;
            margin: 0 auto;

            .progressive-jp-sport & {
                background: map-get($pjp-color-schema, 'sport', 'border');
            }
            .progressive-jp-casino & {
                background: map-get($pjp-color-schema, 'casino', 'border');
            }
        }

        .progressive-jp-full &:not(.progressive-jp-prize-tax)::after {
            display: none;
        }
    }
    &-full &-prize {
        margin-top: 2px;

        .currency {
            font-size: 32px;
            line-height: normal;
        }

        &::after {
            margin-top: 4px;
        }
    }
    &-full &-bottom {
        margin-top: 4px;
    }
    &-tax {
        .progressive-jp-sport & {
            color: map-get($pjp-color-schema, 'sport', 'caption');
        }

        .progressive-jp-casino & {
            color: map-get($pjp-color-schema, 'casino', 'caption');
        }
    }
    &-read-more {
        color: inherit;

        &:hover {
            color: inherit;
        }
    }
}
</style>
